import React, { useState, useEffect, useRef } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import Autocomplete from '../../Form/Field/Autocomplete/Single.js'
import DateTime from '../../Form/Field/DateTime.js'
import Show from '../../Form/Field/Image/Show.js'
import Choose from '../../Form/Field/Image/Choose.js'
import Field from '../Field.js'
import config from '../../../config.js'

export default props => {

    const ref = useRef()
    const [categories, setCategories] = useState([])
    const [extended] = useState(!!props?.user)
    const context = useOutletContext()

    useEffect(() => {
        props.menu.dispatch(
            props.menu.actions.remove(['move', 'remove'])
        )
    }, [])

    useEffect(async () => {
        const categories = await context.api.panel.get('/categories')
        setCategories(categories)
    }, [])

    useEffect(() => ref.current.focus(), [ref.current])

    return <div className={'main' + (props?.image ? ' image' : '')}
        style={{ backgroundImage: props?.image?.url
            ? `url(${config.images.url + '/' + props.image.url})` : ''}}>
        <div className="text">
            {extended && 
                <div className="category">
                    <Form.Select value={props.category} title="Категорія"
                        onChange={
                            event => props.onChange('category', event.target.value)
                        }>
                        {categories.map(category => (
                            <option value={category._id} key={category._id}>
                                {category.title}
                            </option>
                        ))}
                    </Form.Select>
                </div>
            }
            <h1 contentEditable="true" suppressContentEditableWarning="true"
                onBlur={
                    event => props.onChange('title', event.target.textContent)
                }
                onPaste={props.onPaste} ref={ref} className="editable">
                {props.title}
            </h1>
            {extended &&
                <Autocomplete name="user" value={props.user} label=""
                    title="Автор" path="/users" className="user" required />
            }
            {extended &&
                <DateTime className="date" label="" />
            }
        </div>
        <div className="image">
            <Field name="image.source" value={props.image?.source}
                title="Джерело зображення" className="source"
                onChange={props.onChange} />
            <div className="buttons">
                {props?.image?.url
                    ? <Show as="menu" name={props.image.url} className="menu"
                        onChange={() => props.onChange('image.url')}
                        label="Видалити зображення" />
                    : <Choose library={true}
                        onChange={value => props.onChange('image.url', value)} />
                }
            </div>
            <Field name="image.author" value={props.image?.author}
                title="Автор зображення" className="author"
                onChange={props.onChange} />
        </div>
    </div>
}