// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
   min-height: 100%; font-size: calc(12px + 0.5vw);
   --bs-secondary-color: #e9ecef;
  & body {
    min-height: 100vh; color: #303030; line-height: calc(130% + 0.5vw); background-color: #ffffff;
    padding: 0; margin: 0; position: relative;
    & header, footer {
      & a {
        &.active { /*pointer-events: none;cursor: default; */ text-decoration: none; color: black; }
      }
    }
    & img { border-radius: 1%; }
    h1, h2, article p, #cloud { font-weight: 500; font-family: 'Lora', serif; }
    & main {
      #body { min-height: 50vh; margin: 0 auto;
        /*
        &.width-small { max-width: 640px; }
        &.width-medium { max-width: 768px; }
        &.width-large { max-width: 960px; }
        &.width-full { max-width: initial; }
        & table {
          & tr {
            &.text-muted {
              & th, td { color: var(--bs-gray) !important; }
            }
          }
        }
      */
      }
      .mw-sm { max-width: 576px; }
      .mw-md { max-width: 768px; }
      .mw-lg { max-width: 992px; }
      .screen {
        min-width: 100vw; height: 50vh;text-align: center;vertical-align: middle; display: table-cell;
      }
    }
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/index.css"],"names":[],"mappings":"AAAA;GACG,gBAAgB,EAAE,6BAA6B;GAC/C,6BAA6B;EAC9B;IACE,iBAAiB,EAAE,cAAc,EAAE,+BAA+B,EAAE,yBAAyB;IAC7F,UAAU,EAAE,SAAS,EAAE,kBAAkB;IACzC;MACE;QACE,WAAW,yCAAyC,EAAE,qBAAqB,EAAE,YAAY,EAAE;MAC7F;IACF;IACA,QAAQ,iBAAiB,EAAE;IAC3B,4BAA4B,gBAAgB,EAAE,0BAA0B,EAAE;IAC1E;MACE,QAAQ,gBAAgB,EAAE,cAAc;QACtC;;;;;;;;;;;;OAYD;MACD;MACA,SAAS,gBAAgB,EAAE;MAC3B,SAAS,gBAAgB,EAAE;MAC3B,SAAS,gBAAgB,EAAE;MAC3B;QACE,gBAAgB,EAAE,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,EAAE,mBAAmB;MAC/F;IACF;EACF;AACF","sourcesContent":["html {\n   min-height: 100%; font-size: calc(12px + 0.5vw);\n   --bs-secondary-color: #e9ecef;\n  & body {\n    min-height: 100vh; color: #303030; line-height: calc(130% + 0.5vw); background-color: #ffffff;\n    padding: 0; margin: 0; position: relative;\n    & header, footer {\n      & a {\n        &.active { /*pointer-events: none;cursor: default; */ text-decoration: none; color: black; }\n      }\n    }\n    & img { border-radius: 1%; }\n    h1, h2, article p, #cloud { font-weight: 500; font-family: 'Lora', serif; }\n    & main {\n      #body { min-height: 50vh; margin: 0 auto;\n        /*\n        &.width-small { max-width: 640px; }\n        &.width-medium { max-width: 768px; }\n        &.width-large { max-width: 960px; }\n        &.width-full { max-width: initial; }\n        & table {\n          & tr {\n            &.text-muted {\n              & th, td { color: var(--bs-gray) !important; }\n            }\n          }\n        }\n      */\n      }\n      .mw-sm { max-width: 576px; }\n      .mw-md { max-width: 768px; }\n      .mw-lg { max-width: 992px; }\n      .screen {\n        min-width: 100vw; height: 50vh;text-align: center;vertical-align: middle; display: table-cell;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
