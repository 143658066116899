// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  .alert { max-width: 768px; margin: 20px auto; }
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/layouts/main/footer.css"],"names":[],"mappings":"AAAA;EACE,SAAS,gBAAgB,EAAE,iBAAiB,EAAE;AAChD","sourcesContent":["footer {\n  .alert { max-width: 768px; margin: 20px auto; }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
