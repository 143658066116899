// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
  div.field {
    div.image { position: relative;
      & img { width: 100%; }
      & div.menu { position: absolute; top: 5px; right: 5px; z-index: 1; opacity: 0.25;
        & button { margin: 5px; }
      }
      &:hover  {& div.menu { opacity: 1.0; }}
    }
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/fields/image.css"],"names":[],"mappings":"AAAA;EACE;IACE,YAAY,kBAAkB;MAC5B,QAAQ,WAAW,EAAE;MACrB,aAAa,kBAAkB,EAAE,QAAQ,EAAE,UAAU,EAAE,UAAU,EAAE,aAAa;QAC9E,WAAW,WAAW,EAAE;MAC1B;MACA,UAAU,aAAa,YAAY,EAAE,CAAC;IACxC;EACF;AACF","sourcesContent":["form {\n  div.field {\n    div.image { position: relative;\n      & img { width: 100%; }\n      & div.menu { position: absolute; top: 5px; right: 5px; z-index: 1; opacity: 0.25;\n        & button { margin: 5px; }\n      }\n      &:hover  {& div.menu { opacity: 1.0; }}\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
