export default {
    brand: 'MediaCMS',
    title: 'Панель керування',
    api: {
        url: 'https://panel.mediacms.org/api',
        timeout: 20_000
    },
    main: {
        url: 'https://demo.mediacms.org',
        key: '7m3v2nZBB7xBCMAgHb5WrkHGvjuNY55T',
        timeout: 10_000
    },
    images: {
        url: 'https://image.mediacms.org',
        key: '9e7f1D53edf0a5E9b259b3e679C65ec6',
        types: { 'image/jpeg': 'jpg' },
        size: 10 * 1024 ** 2,
        timeout: 30_000
    },
    google: {
        recaptcha: {
            key: '6LfrF04UAAAAAF71hi5Q-X5Blvwpibv6r6BSwsJa'
        },
    },
    tinymce: '/tinymce/tinymce.min.js',
    highlight: {
        languages: {
            count: 5,
            list: {
                txt: 'Text', xml: 'XML', css: 'CSS', html: 'HTML', json: 'JSON',
                yaml: 'YAML', cpp: 'C++', java: 'Java', python: 'Python',
                javascript: 'JavaScript', typescript: 'TypeScript',
                perl: 'Perl', php: 'PHP'
            }
        }
    },
    months: [
        'січня', 'лютого', 'березня', 'квітня', 'травня', 'червня',
        'липня', 'серпня', 'вересня', 'жовтня', 'листопада', 'грудня'
    ],
    copyright: '2024',
    mode: 'production',
    debug: true,
    limit: 100
}
