export default {
    host: 'panel.mediacms.org',
    protocol: 'https',
    ip: '127.0.1.3',
    port: 8888,
    path: '/api',
    root: '/home/artem/www/mediacms/panel',
    db: { 
        url: 'mongodb://mediacms_rw:hRzSkTn4QTVrzML8@localhost:27017/mediacms',
        options: {
            connectTimeoutMS: 15_000,
            socketTimeoutMS: 15_000,
            serverSelectionTimeoutMS: 15_000,
            useUnifiedTopology: true
        }
    },
    jwt: {
        key: '7m3v2nZBB7xBCMAgHb5WrkHGvjuNY55T'        
    },
    cookie: {
        maxAge: 1_000 * 60 * 60 * 24
    },
    google: {
        recaptcha: {
            url: 'https://www.google.com/recaptcha/api/siteverify',
            key: '6LfrF04UAAAAAJu-1INwk9HbHMmjK_zRac2TbyNa'
        }
    },
    limit: 100,
    log: '/log'
}
