// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.modal-fullscreen .modal-content { height: auto !important; }
form {
    & input[type='datetime-local'],
    input[type='number'],
    select { width: auto; }
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/form.css"],"names":[],"mappings":";AACA,mCAAmC,uBAAuB,EAAE;AAC5D;IACI;;aAES,WAAW,EAAE;AAC1B","sourcesContent":["\n.modal-fullscreen .modal-content { height: auto !important; }\nform {\n    & input[type='datetime-local'],\n    input[type='number'],\n    select { width: auto; }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
