// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.autocomplete {
  & button {
    &:hover {
      color: var(--bs-white); border-color: var(--bs-danger);
      background-color: var(--bs-danger); text-decoration: line-through;
    }
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/fields/autocomplete.css"],"names":[],"mappings":"AAAA;EACE;IACE;MACE,sBAAsB,EAAE,8BAA8B;MACtD,kCAAkC,EAAE,6BAA6B;IACnE;EACF;AACF","sourcesContent":[".autocomplete {\n  & button {\n    &:hover {\n      color: var(--bs-white); border-color: var(--bs-danger);\n      background-color: var(--bs-danger); text-decoration: line-through;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
