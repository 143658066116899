// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  & tr.text-muted {
    &:hover {
      th, td { color: initial !important; }
    }
    & th, td { color: #c2c9d6 !important; }
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/table.css"],"names":[],"mappings":"AAAA;EACE;IACE;MACE,SAAS,yBAAyB,EAAE;IACtC;IACA,WAAW,yBAAyB,EAAE;EACxC;AACF","sourcesContent":["table {\n  & tr.text-muted {\n    &:hover {\n      th, td { color: initial !important; }\n    }\n    & th, td { color: #c2c9d6 !important; }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
