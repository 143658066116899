// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.images { margin: -1px;
  & div.image {
    width: 152px; height: 114px; line-height: 95px;
    text-align: center; vertical-align: middle; padding: 10px;
    & img { max-width: 100%; max-height: 100%; object-fit: cover; }
    &.image-muted {
      & img {
        opacity: 0.5;
        &:hover { opacity: 1; }
      }
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/images.css"],"names":[],"mappings":"AAAA,aAAa,YAAY;EACvB;IACE,YAAY,EAAE,aAAa,EAAE,iBAAiB;IAC9C,kBAAkB,EAAE,sBAAsB,EAAE,aAAa;IACzD,QAAQ,eAAe,EAAE,gBAAgB,EAAE,iBAAiB,EAAE;IAC9D;MACE;QACE,YAAY;QACZ,UAAU,UAAU,EAAE;MACxB;IACF;EACF;AACF","sourcesContent":["div.images { margin: -1px;\n  & div.image {\n    width: 152px; height: 114px; line-height: 95px;\n    text-align: center; vertical-align: middle; padding: 10px;\n    & img { max-width: 100%; max-height: 100%; object-fit: cover; }\n    &.image-muted {\n      & img {\n        opacity: 0.5;\n        &:hover { opacity: 1; }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
