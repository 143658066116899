import React from 'react'
import { NavLink } from 'react-router-dom'
import config from '../../config.js'
import '../../assets/styles/layouts/main/footer.css'

export default function (props) {

    return (
        <footer className="text-center mt-5">
            <ul className="nav justify-content-center">
                {props?.menu && props.menu.map((item, index) => (
                    <li className="nav-item" key={index}>
                        <NavLink
                            to={item.path}
                            className="nav-link small p-2"
                            title={item.description}>
                            {item.title}
                        </NavLink>
                    </li>
                ))}
            </ul>
            <p className="text-muted small mt-3" title={config.brand}>
                {config.brand} &copy; {config.copyright}
            </p>
        </footer>
    )
}